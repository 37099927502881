<template lang="pug">
.give-page.card
  .nav.nav-tabs
    .nav-link(:class="{active: currentTab != 'subsplash'}" @click="currentTab = 'direct'") Direct Payment
    .nav-link(:class="{active: currentTab == 'subsplash'}" v-if="false" @click="currentTab = 'subsplash'") Subsplash
  .card-body(v-if="currentTab == 'subsplash'")
    <iframe src="https://wallet.subsplash.com/ui/embed/G2XZK8" width="100%" height="630" style="border:none;overflow:hidden" frameborder="0" scrolling="no"></iframe>
  .card-body(v-else)
    h1 {{ $t("message.tithesAndOfferings") }}
    form.form(@submit.prevent v-if="!lockAmount")
      .row.mb-3.text-start
          label {{ $t("message.type") }}:
          .col-6
              select.form-select(style="height: calc(3.5rem + 2px)")
                  option(selected) {{ $t("message.tithes") }}
                  option {{ $t("message.offerings") }}
                  option {{ $t("message.event") }} 
          .form-floating.col-6
              input.form-control(type="number", v-model="amount", placeholder="")
              label {{ $t("message.amount") }} ($ Dolars)
      .form-floating.mb-3
          input.form-control(type="text", v-model="description", placeholder="")
          label {{ $t("message.description") }}
      .form-floating.mb-3(v-if="!$attrs.user.userid")
          input.form-control(type="email", v-model="email", placeholder="")
          label {{ $t("message.email") }}
      button.btn.btn-outline-secondary(@click="showPaymentForm") {{ $t("message.proceedToPayment") }}
    StripeForm(
      v-if="lockAmount",
      :price="parseInt(amount * 100)",
      :userid="$attrs.user.userid || email"
      :description="description",
    )
</template>

<script>
import StripeForm from "./../components/StripeForm.vue";
export default {
  params: ["user"],
  components: { StripeForm },
  data() {
    return {
      amount: 0,
      description: "",
      email: "",
      currentTab: 'direct',
      lockAmount: false,
    };
  },
  mounted() {
    console.log("mounted givings", this.$attrs.user);
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (clientSecret) {
      this.lockAmount = true;
    }
  },
  methods:{
    showPaymentForm(){
      if(this.amount < 3){
        return alert(this.$i18n.t("message.minimumAmount") +  "$3.00");
      }
      if(this.description.length < 10){
        return alert("Add a description");
      }
      if(!this.$attrs.user.userid){
        if(this.email.length < 5){
          return alert("Please add an email");
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isEmail = emailRegex.test(this.email);
        if(!isEmail){
          return alert("Please add a vailid email");
        }
      }
      this.lockAmount = true;
    }
  }
};
</script>

<style>
</style>